import type { SiteConfiguration } from "./types";

export const SITE_CONFIG: SiteConfiguration = {
  description: `HostifAI Web App`,
  title: "HostifAI",
  titleSeparator: " · ",

  domain: `hostifai.com`,
  siteUrl: `https://www.hostifai.com`,
} as const;
