import { SITE_CONFIG } from "~config/site";

import { MainContainer } from "./LoginLayout.style";

import Head from "next/head";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import type { FC, PropsWithChildren } from "react";

const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      </Head>

      <DefaultSeo
        canonical={SITE_CONFIG.siteUrl + router.asPath}
        defaultTitle={SITE_CONFIG.title}
        description={SITE_CONFIG.description}
        titleTemplate={`%s ${SITE_CONFIG.titleSeparator} ${SITE_CONFIG.title}`}
      />

      <MainContainer>{children}</MainContainer>
    </>
  );
};

export default LoginLayout;
